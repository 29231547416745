import React, { useEffect, useState } from "react";
import "./Snowflakes.scss";

const SNOWFLAKE_COUNT: number = 10;

export const Snowflakes: React.FC = (props) => {
  const flakes: any[] = [];
  for (let i = 0; i < SNOWFLAKE_COUNT; i++) {
    flakes.push("f");
  }

  return (
    <div className="snowflakes" aria-hidden="true">
      {flakes.map((val, i: number) => {
        return (
          <div className="snowflake" key={i}>
            ❅
          </div>
        );
      })}
      {/* <div className="snowflake">❅</div>
      <div className="snowflake">❅</div>
      <div className="snowflake">❆</div> */}
      {/* <div class="snowflake">❄</div>
    <div class="snowflake">❅</div>
    <div class="snowflake">❆</div>
    <div class="snowflake">❄</div>
    <div class="snowflake">❅</div>
    <div class="snowflake">❆</div>
    <div class="snowflake">❄</div> */}
      {/* <div className="snowflake">❅</div>
      <div className="snowflake">❆</div>
      <div className="snowflake">❄</div>
      <div className="snowflake">❅</div>
      <div className="snowflake">❆</div> */}
    </div>
  );
};

export default Snowflakes;
