import React, { useEffect, useState } from "react";
import "./Logo.scss";

export const Logo: React.FC = (props) => {
  return (
    <div className="logo-container">
      <h1 className="logo">Snowboard Dreams</h1>
      <a
        className="main-link"
        href="mailto:dana@snowboardreams.com"
        target="_blank"
      >
        dana@snowboardreams.com
      </a>
    </div>
  );
};

export default Logo;
