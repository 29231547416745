import React from "react";
import Logo from "./logo/Logo";
import Snowflakes from "./snowflakes/Snowflakes";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Logo />
      </header>
      <Snowflakes />
    </div>
  );
}

export default App;
